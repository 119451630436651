<template>
  <div>
            <el-form-item :label="label" :prop="prop" :required="required">
              <el-select
                v-model="currentValue"
                :name="name"
                :placeholder="placeholder"
                filterable
                clearable
                remote
                :filter-method="onFilterMethod"
                :remote-method="onFilterMethod"
                @change="onChange"
                :clear="onClear"
                :loading="loading"
                @click.native="onFocus()"                
              >
                <el-option
                  v-for="item in options"
                  :key="item[optionKey]"
                  :label="item[optionLabel]"
                  :value="item[optionValue]"
                  :disabled="item[optionDisabled]"
                >
                  <span style="float: left">{{ item[optionLabel] }}</span>
                  <span v-if="item[optionAlias]" style="float: right; color: #8492a6; font-size: 13px">{{ item[optionAlias] }}</span>
                </el-option>
              </el-select>
            </el-form-item>
  </div>
</template>

<script>
export default {
  name: 'kSelect',
  model: {
    prop: 'value',
    event: 'update',
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    value: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
    filterable: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: ""
    },
    prop: {
      type: String,
      default: '',
    },
    optionKey: {
      type: String,
      default: 'id',
    },
    optionLabel : {
      type: String,
      default: 'name',
    },
    optionValue: {
      type: String,
      default: 'id',
    },
    optionAlias: {
      type: String,
      default: '',
    },
    optionDisabled: {
      type: String,
      default: "disabled"
    },
    change: {
      type: Function,
      default: (id) => (id),
    },
    filterMethod: {
      type: Function,
      default: (id) => (id),
    },
    disableWhen: {
      type: Function,
      default: (option) => false,
    }
  },
  data () {
    return {
      currentValue: this.value,
      loading: false,
      options: [],
    }
  },
  created: function () { 
  },
  watch: {
    value: {
      deep: true,
      handler(n, o) {
        console.log("new, old", n, o)
        if(o == null) {
          this.currentValue = n
          this.onFocus()
        }
        this.$emit('update', n)
      }
    }
  },
  methods: {
    clear() {
      this.onChange(null)
    },
    onChange(val) {
      console.log("onChange", val)
      this.currentValue = val
      this.$emit('update', this.currentValue)
    },
    onClear() {
      console.log("kSelect onClear")
      this.onChange(null)
      this.onFilterMethod(null)
    },
    onFocus() {
      console.log("kSelect onFocus", this.currentValue)
      if(this.options.length!=0) return;

      this.onFilterMethod(null)
    },
    onFilterMethod(val) {
      const that = this
      this.filterMethod({name:val}).then(res => {
        if(res.code===200) {
          let options = res.data
          options.forEach(option => {
            option[this.optionDisabled] = this.disableWhen(option)
          })
          that.options = options
        } else {
          that.$message.error(res.message)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
