<template>
  <div>
    <div class="contents">
      <div class="tableSearch">
          <el-form :inline="true" ref="searchForm" :model="searchForm" label-width="90px">
            <el-form-item label="结算单号" style="margin-bottom:10px">
              <el-input v-model="searchForm.id" clearable  style="width:190px"
                placeholder="请输入结算单号"></el-input>
            </el-form-item>

            <el-form-item label="订单号" style="margin-bottom:10px">
              <el-input v-model="searchForm.orderId" clearable  style="width:190px"
                placeholder="请输入订单号"></el-input>
            </el-form-item>

            <el-form-item label="商品名称" style="margin-bottom:10px">
              <el-input v-model="searchForm.goodsName" clearable  placeholder="商品名称" style="width:190px"></el-input>
            </el-form-item>

            <el-form-item label="账单名称" style="margin-bottom:10px">
              <el-input v-model="searchForm.name" clearable  placeholder="请输入账单名称" style="width:190px"></el-input>
            </el-form-item>

            <el-form-item label="渠道(团长)" style="margin-bottom:10px">
              <el-input v-model="searchForm.retailerName" clearable  style="width:190px"
                placeholder="请输入渠道(团长)名称"></el-input>
            </el-form-item>

            <el-form-item label="创建时间" style="margin-bottom:10px">
              <el-date-picker v-model="searchForm.date" type="daterange" range-separator="至"
                start-placeholder="开始时间" end-placeholder="结束时间">
              </el-date-picker>            

            </el-form-item>
            <el-button @click="createSearch" type="primary" icon="el-icon-search" :loading="loading">查询</el-button>
            <el-button @click="chongzhi">重置</el-button>
            <el-button type="primary" icon="el-icon-tickets" @click="() => makeCustomFlag = true">制作自定义账单</el-button>
          </el-form>
      </div>

    <div class="mainbox">

  <el-tabs v-model="active" @tab-click="changeTab" style="margin-bottom:20px;">
    <el-tab-pane label="已创建" name="0">
        <span slot="label">已创建({{counts['s0']?counts['s0']:0}})</span>        
        <el-button plain @click="confirm" type="primary" size="small" icon="el-icon-check">确认并发送账单收款</el-button>
        <el-button plain @click="invalidate" type="primary" size="small" icon="el-icon-delete">作废账单</el-button>

        <el-button plain @click="showAdjustDialog()" type="primary" size="small" icon="el-icon-edit-outline">调整账单</el-button>

        <el-button plain @click="settledOffline" type="primary" size="small" icon="el-icon-document-checked">线下结清账单</el-button>
    </el-tab-pane>
    <el-tab-pane name="3">
        <span slot="label">待付款({{counts['s3']?counts['s3']:0}})</span>

        <el-button plain @click="invalidate" type="primary" size="small" icon="el-icon-delete">作废账单</el-button>

        <!-- <el-button plain @click="merge" type="primary" size="small" icon="el-icon-folder">合并账单</el-button> -->

        <el-button plain @click="settledOffline" type="primary" size="small" icon="el-icon-document-checked">线下结清账单</el-button>

        <el-button plain @click="remind" type="primary" size="small" icon="el-icon-phone-outline">催收账单</el-button>

        <el-button plain @click="showAdjustDialog()" type="primary" size="small" icon="el-icon-edit-outline">调整账单</el-button>
    </el-tab-pane>
    <el-tab-pane name="1">
        <span slot="label">已结清({{counts['s1']?counts['s1']:0}})</span>
    </el-tab-pane>
    <el-tab-pane name="2">
        <span slot="label">已作废({{counts['s2']?counts['s2']:0}})</span>
    </el-tab-pane>
    <el-tab-pane  name="-1">
      <span slot="label">全部({{counts['s']?counts['s']:0}})</span>
    </el-tab-pane>
  </el-tabs>


       <el-table
        :data="tableData"
        border
        @selection-change="handleSelectionChange"
        :header-cell-style="{background:'#f7f7f7', color:'#666'}" stripe
        style="width: 100%">
        <el-table-column
          type="selection"
          width="40" header-align="center" align="center" fixed>
        </el-table-column>
        
        <el-table-column
          label="结算单号"
          width="180"
          fixed
          >
          <template slot-scope="scope">
            {{scope.row.id}}
          </template>
        </el-table-column>

        <el-table-column
        fixed
          label="渠道(团长)">
          <template slot-scope="scope">
            {{scope.row.payOrderSnapshot.detail.retailer.name}}
          </template>
        </el-table-column>

        <el-table-column
          label="账单名称"
          width="180"
          >
          <template slot-scope="scope">
            {{scope.row.name}}
          </template>
        </el-table-column>

        <el-table-column
          label="商品数量">
          <template slot-scope="scope">
            {{scope.row.payOrderSnapshot.detail.totalGoods}}
          </template>
        </el-table-column>

        <el-table-column
          label="金额(元)">
          <template slot-scope="scope">
            {{scope.row.price}}
          </template>
        </el-table-column>

        <!-- <el-table-column
          label="备注">
          <template slot-scope="scope">
            {{scope.row.remark}}
          </template>
        </el-table-column> -->

        <el-table-column
          label="创建时间"
          width="160"
          >
          <template slot-scope="scope">
            {{scope.row.createTime}}
          </template>
        </el-table-column>

        <el-table-column
          label="结清时间"
          width="160"
          >
          <template slot-scope="scope">
            {{scope.row.payTime}}
          </template>
        </el-table-column>

        <el-table-column
          label="账单状态"
          width="80"
          >
          <template slot-scope="scope">
            {{scope.row.payOrderStatusDescription}}
          </template>
        </el-table-column>

        <el-table-column
          label="结算方式"
          width="120"
          >
          <template slot-scope="scope">
            {{scope.row.payTypeDescription}}
          </template>
        </el-table-column>

        <el-table-column
          label="备注"
          width="120"
          >
          <template slot-scope="scope">
            {{scope.row.remark}}
          </template>
        </el-table-column>

         <el-table-column
      fixed="right"
      label="操作"
      width="160" header-align="center" align="center">
      <template slot-scope="scope">
        <el-button @click="showDetail(scope.row)" type="text" size="small">查看详情</el-button>

        <el-button @click="down(scope.row)" type="text" size="small">下载账单</el-button>        
      </template>
    </el-table-column>
      </el-table>
      <div class="paginations">
          <el-pagination
            background
            layout="total, prev, pager, next, sizes"
            :page-sizes="[20, 50, 100, 200, 400]"
            @current-change="changePage" :current-page="page" :page-size="pageSize" @size-change="handleSizeChange"
            :total="total">
          </el-pagination>
      </div>
      </div>
    </div>

    <pay-order-detail ref="payOrderDetail"></pay-order-detail>

    <el-dialog title="线下结清凭证" :visible.sync="pingzhengFlag" :before-close="layerClose" width="600px">       
      <el-upload style="margin:0px auto;width:400px;"
        class="upload-demo"
        action="string"
        drag
        multiple
        :on-preview="handlePreview"
        :on-remove="handleRemove"
        accept="image/png, image/jpeg"
        :file-list="fileList"
        :http-request="uploadImage"
        list-type="picture">
        <el-button size="small" type="text" style="margin:80px 0px 0px 0px;">点击上传转账或者支付凭证图片</el-button>
        <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过1MB</div>
      </el-upload>
            <div class="dialog-footer" slot="footer">           
              <el-button @click="() => pingzhengFlag=false">取消</el-button>
              <el-button type="primary" @click="settled">确定</el-button>
            </div>
    </el-dialog>

    <el-dialog title="调整账单" :visible.sync="adjustFlag" :before-close="layerClose" width="600px">
        <el-form v-model="adjust" label-width="100px;" label-position="right">
            <el-form-item label="调账金额" required>
                <el-input-number :precision="2" v-model="adjust.money"></el-input-number>
                <span style="font-size:12px;color:red;margin-left:20px;">金额区分正、负数，负数表示从原始账单金额中扣除，正数为增加</span>
            </el-form-item>
            <el-form-item label="调账原因" required>
                <el-input v-model="adjust.reason" type="textarea" style="width:400px;"></el-input>
            </el-form-item>
        </el-form>
            <div class="dialog-footer" slot="footer">           
              <el-button @click="() => adjustFlag=false">取消</el-button>
              <el-button type="primary" @click="adjustIt()">确定</el-button>
            </div>
    </el-dialog>

    <el-dialog title="制作自定义账单" :visible.sync="makeCustomFlag" :before-close="layerClose" width="500px">
        <el-form v-model="custom" label-width="200px;" label-position="right">
            <k-select ref="collectorSelector"
                v-model="custom.targetOrgId"
                :name="custom.targetOrgName"
                label="出账渠道"
                placeholder="请选择出账渠道"
                optionKey="id"
                optionValue="targetOrgId"
                optionLabel="name"
                optionAlias="alias"
                clearable
                :filterMethod="listRetailer"
                required
            >
            </k-select>
            <el-form-item label="账单开始日期" required >
                <el-date-picker type="date" v-model="custom.startTime" placeholder="选择开始日期"></el-date-picker>
            </el-form-item>
            <el-form-item label="账单截止日期" required>
                <el-date-picker type="date" placeholder="选择截止日期" v-model="custom.endTime"></el-date-picker>
            </el-form-item>
        </el-form>
        <p class="note"><span class="red">出账逻辑：</span>系统会拉取开始日期 00:00:00至截止日期23:59:59期间的所有未付款订单，以及该周期内已付款被取消或线上退款的订单进行出账。</p>
            <div class="dialog-footer" slot="footer">           
              <el-button @click="() => makeCustomFlag=false">取消</el-button>
              <el-button type="primary" @click="createBill()" :loading="making">制作</el-button>
            </div>
    </el-dialog>


  </div>
</template>

<script>
import Vue from 'vue'
import { Loading } from 'element-ui';
import Clipboard from 'clipboard'
import { uploadFile } from "@/api/common";
import { search, invalidatePayOrder, confirmPayOrder, settledPayOrder, 
remindPayOrder, adjustPayOrder, mergePayOrder, download, makeCustomBill
 } from "@/api/payorder";
import { listRetailer } from "@/api/retailer";
import { MessageBox } from 'element-ui'
import passWord from "@/components/password";
import PayOrderDetail from "./detail"
import kSelect from "@/components/kselect"


let moment = require('moment');
Vue.prototype.Clipboard = Clipboard
export default {
  name: 'Orderss',
  components: { passWord, PayOrderDetail, kSelect },
  data() {
    return {
      searchForm: {
      },
      role: 'supplier',
      newOrderArr: [],
      total: 0,
      page: 1,
      pageSize: 20,
      tableData: [],
      active: 0,
      loading: false,
      counts: {},
      multipleSelection: [],
      pingzhengFlag: false,
      fileList: [],
      adjust: {},
      adjustFlag: false,      // 调账
      makeCustomFlag: false, // 制作自定义账单
      custom: {},
      listRetailer: listRetailer,
      making: false,
    }
  },
  methods: {
    createBill(){
      let param = this.custom;
      param.startTime = param.startTime ? moment(param.startTime).format('YYYY-MM-DD 00:00:00') : '';
      param.endTime = param.endTime ? moment(param.endTime).format('YYYY-MM-DD 23:59:59') : '';
      param.role = this.role;
      this.making = true
      makeCustomBill(param).then(res=>{
        this.making = false
        if(res.code===200) {
          this.layerClose()
          this.getTopSearch()          
          this.$message.success(res.message)
        } else {
          this.$message.error(res.message)
        }
      })
    },
    down(row) {
      const loading = this.$loading({
        text: "正在下载，请稍后"
      })
      download(row).then(res => {
        loading.close()
        if(res.code == 200) {
          if(res.data) {
            window.open(res.data)
          }
        } else {
          this.$message.error(res.message)
        }
      }).catch(err => {
        loading.close()
      })
    },
    showDetail(row) {
      this.$refs.payOrderDetail.open(row)
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    adjustIt() {
      if(this.multipleSelection.length == 0) {
        this.$message.warning("请先选择账单")
        return;
      }
      const param = {
        ids: this.multipleSelection.map(it => it.id),
        adjust: this.adjust
      }
      adjustPayOrder(param).then(res=>{
        if(res.code===200) {
          this.$message.success(res.message)
          this.layerClose()
          this.getTopSearch()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    remind() {
      if(this.multipleSelection.length == 0) {
        this.$message.warning("请先选择账单")
        return;
      }
      const param = {
        ids: this.multipleSelection.map(it => it.id),
      }
      remindPayOrder(param).then(res=>{
        if(res.code===200) {
          this.$message.success(res.message)
        } else {
          this.$message.error(res.message)
        }
      })
    },
    showAdjustDialog() {
      if(this.multipleSelection.length == 0) {
        this.$message.warning("请先选择账单")
        return;
      }
      this.adjust = {}
      this.adjustFlag = true
    },
    settled(){
      console.log("settled", this.fileList)
      if(this.multipleSelection.length == 0) {
        this.$message.warning("请先选择账单")
        return;
      }
      if(this.fileList.length == 0) {
        this.$message.error("请上传结款凭证")
        return;
      }
      const param = {
        ids: this.multipleSelection.map(it => it.id),
        certificates: this.fileList.map(it => it.url)
      }
      const that = this
      settledPayOrder(param).then(res => {
        if(res.code === 200) {
          this.$message.success(res.message)
          this.getTopSearch();
          this.layerClose();
          that.fileList = []
        } else {
          this.$message.error(res.message)
        }
      })
    },
    merge() {
      if(this.multipleSelection.length == 0) {
        this.$message.warning("请先选择待合并的账单")
        return;
      }

      this.$confirm('确定要合并账单吗，合并后原账单会被作废，同时生成一份新账单?', '温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const param = {
          ids: this.multipleSelection.map(it => it.id)
        }
        mergePayOrder(param).then(res=>{
          if(res.code===200) {
            this.$message.success(res.message)
            this.getTopSearch()
          } else {
            this.$message.error(res.message)
          }
        })         
      }).catch()
    },
    settledOffline() {
      if(this.multipleSelection.length == 0) {
        this.$message.warning("请先选择待线下结清的账单")
        return;
      }
      this.pingzhengFlag = true
    },
    confirm() {
      if(this.multipleSelection.length == 0) {
        this.$message.warning("请先选择账单")
        return;
      }
      const param = {
        ids: this.multipleSelection.map(it => it.id),
        role: this.role,
      }
      confirmPayOrder(param).then(res=>{
        if(res.code===200) {
          this.$message.success(res.message)
          this.getTopSearch()
        } else {
          this.$message.error(res.message)
        }
      })
    },    
    invalidate() {
      if(this.multipleSelection.length == 0) {
        this.$message.warning("请先选择账单")
        return;
      }

      this.$confirm('确定要作废账单吗，作废后不可还原?', '温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const param = {
          ids: this.multipleSelection.map(it => it.id)
        }
        invalidatePayOrder(param).then(res=>{
          if(res.code===200) {
            this.$message.success(res.message)
            this.getTopSearch()
          } else {
            this.$message.error(res.message)
          }
        })         
      }).catch()
    },
    changeTab(tab, event) {
      this.tableData = []
      this.getTopSearch()
    },
    handleSizeChange(val){
      this.pageSize = val
      this.getTopSearch()
    },
    createSearch() {
      this.page = 1
      this.getTopSearch()
    },    
    chongzhi(){
      this.searchForm= {}
      this.getTopSearch()
    },
    getTopSearch(){
      var that = this
      var datas = that.searchForm
      datas.role = that.role
      datas.status = that.active == "-1" ? null : that.active;
      datas.pageNo = that.page;
      datas.pageSize = that.pageSize;
      datas.orderByCreated = "DESC";
      datas.startTime = that.searchForm.date && that.searchForm.date.length>0 ? moment(that.searchForm.date[0]).format('YYYY-MM-DD 00:00:00') : '';
      datas.endTime = that.searchForm.date &&  that.searchForm.date.length>0 ? moment(that.searchForm.date[1]).format('YYYY-MM-DD 23:59:59') : '';
      that.loading = true
      search(datas).then(res => {
        that.loading = false
        if(res.code ===200) {
          var nodataArr = res.data.rows || []
          that.tableData = nodataArr
          that.total = res.data.totalRows
          that.counts = res.data.counts
        }else{
          this.$message.error(res.message)
        }
      })
    },    
    changePage (newPage) {
      var that = this
      if (that.page === newPage) {
        return
      }
      that.page = newPage
      that.getTopSearch()
    },    
    layerClose() {
      this.pingzhengFlag = false
      this.adjustFlag = false
      this.makeCustomFlag = false
    },
    handleRemove(file) {
      for(var i=this.fileList.length-1;i>=0;i--) {
        if(file.url == this.fileList[i].url) {
          this.fileList.splice(i, 1)
        }
      }
    },
    handlePreview(file) {
      console.log("handlePreview", file)
      window.open(file.url)
    },
    uploadImage(param) {
      var that = this;
      const formData = new FormData();
      console.log(param);
      var objs = {};
      formData.append("file", param.file);
      uploadFile(formData)
        .then((res) => {
          if (res.code === 200) {
            console.log("上传图片成功");
            param.onSuccess()
            that.fileList.push({url:res.data.url})
          } else {
            that.$message.error(res.message);
          }
        })
        .catch((response) => {
          console.log("图片上传失败");
          that.$message.error(response.message);
          param.onError();
        });
    }
  },
  mounted(){
    this.getTopSearch()
  }
}
</script>

<style scoped>
.el-pagination.is-background .el-pager li:not(.disabled).active{
  background-color: #07C160;
  color: #FFF;
}
.el-tabs__nav-wrap::after{
  height: 1px;
}
.el-form-item__label{
  font-size: 12px;
}


.caozuoqu{
  float:right;
  display: block;
  padding: 5px 0 10px;
  overflow: hidden;
}
.goodinfos p{ color: #333; padding-left: 60px; display: block; text-align: left;}
.goodinfos span{
  width: 60px;
  display: inline-block;
  text-align: left;
  color: #999;
  margin-left:-50px;
}
.note {
  font-size:12px;
  line-height: 20px;
  margin:10px;
}
</style>