<template >
  <div>
    <el-dialog
      :title="title"
      :visible.sync="kuanflag"
      :before-close="layerClose"
      width="900px"
    >
      <div class="kuanleft-box">
        <el-input
          type="textarea"
          :rows="10"
          v-model="shoukuanBox.content"
          readonly
        ></el-input>
      </div>
      <div class="kuanCode" v-show="!shoukuanBox.isPreview">
        <img :src="shoukuanBox.receiptImage" class="codesize" />
        <p>微信扫码查看账单付款</p>
      </div>

      <!-- <div class="kuanTT">
        <div class="kuanTitle">商品明细</div>
        <el-table
          :data="shoukuanDetail.goods"
          style="width: 100%"
          :header-cell-style="{ background: '#f7f7f7', color: '#666' }"
          border
          max-height="250"
        >
          <el-table-column prop="goodsName" label="商品名"> </el-table-column>
          <el-table-column prop="specName" label="规格" width="120">
          </el-table-column>
          <el-table-column prop="num" label="数量" width="120">
          </el-table-column>
          <el-table-column prop="salePrice" label="供货价" width="120">
          </el-table-column>
        </el-table>
      </div> -->
      <!-- <div class="kuanTT">
        <div class="kuanTitle">订单明细</div>
        <el-table
          :data="shoukuanDetail.orders"
          style="width: 100%"
          :header-cell-style="{ background: '#f7f7f7', color: '#666' }"
          border
          max-height="250"
        >
          <el-table-column prop="onlineOrderId" label="订单号">
          </el-table-column>
          <el-table-column prop="goodsName" label="商品名称"> </el-table-column>
          <el-table-column prop="specName" label="规格"> </el-table-column>
          <el-table-column prop="deliveryPrice" label="运费" width="120">
          </el-table-column>
          <el-table-column prop="orderPrice" label="金额(含运费)" width="180">
          </el-table-column>
          <el-table-column prop="consigneeName" label="收件人" >
          </el-table-column>
          <el-table-column prop="consigneePhone" label="手机" >
          </el-table-column>
        </el-table>
      </div> -->
      <div class="kuanTT">

        <el-descriptions
          class="margin-top"
          title=""
          :column="2"
          border
        >
                
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-user"></i>
              渠道(团长)
            </template>
            {{shoukuanBox.retailerOrgName}}
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-user"></i>
              供应商
            </template>
            {{shoukuanBox.supplierOrgName}}
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-coin"></i>
              金额合计(含运费)
            </template>
                        ￥{{shoukuanDetail.totalPrice}}

          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-coin"></i>
              运费合计
            </template>
            ￥{{shoukuanDetail.totalDeliveryPrice}}
          </el-descriptions-item>    

          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-coin"></i>
              待结清金额
            </template>
                        ￥{{shoukuanBox.waitSettlePrice}}

          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-collection"></i>
              订单数量
            </template>
            {{shoukuanDetail.totalOrder}}
          </el-descriptions-item>    


          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-coin"></i>
              调账金额
            </template>
                        ￥{{shoukuanBox.adjustMoney}}

          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-tickets"></i>
              调账原因
            </template>
            {{shoukuanBox.adjustReason}}
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-shopping-cart-2"></i>
              账单金额
            </template>
            <span class="red">￥{{shoukuanBox.price}}</span>
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-s-flag"></i>
              账单状态
            </template>
            {{shoukuanBox.settleStatus}}
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-note"></i>
              备注
            </template>
            <el-popover
              placement="right"
              width="180"
              v-model="remarkVisible">
              <el-input type="textarea" :row="3" v-model="remarkText"></el-input>
              <div style="text-align: right; margin-top: 10px">
                <el-button size="mini" type="text" @click="cancelEditRemark()">取消</el-button>
                <el-button type="primary" size="mini" @click="confirmEditRemark()">确定</el-button>
              </div>
              
            </el-popover> <i class="el-icon-edit" style="cursor:pointer" @click="modifyRemark">修改</i>
             {{shoukuanBox.remark}} &nbsp; 
            <a v-if="shoukuanBox.payType==8" target="_blank" :href="'/api/file/receipt/pdf?id='+shoukuanBox.id">查看网银打款回执</a>
          </el-descriptions-item>

        </el-descriptions>
        <div class="pay-desc">账单金额 = 订单合计金额（含运费）+ 人工调账金额</div>
      </div>

      <div class="dialog-footer" slot="footer">
        <el-button @click="layerClose">关闭</el-button>
        <el-button type="primary" @click="makeIt" :loading="loading"
        :disabled="!shoukuanBox.isPreview"
          >生成账单</el-button
        >
      </div>

    </el-dialog>
  </div>
</template>
<script>
import Vue from "vue";
import Clipboard from "clipboard";
import { storage, modifyRemark } from "@/api/payorder";
import { MessageBox } from "element-ui";
import passWord from "@/components/password";

let moment = require("moment");
Vue.prototype.Clipboard = Clipboard;
export default {
  name: "PayOrderDetail",
  components: { passWord },
  data() {
    return {
      title: "结算单明细",
      loading: false,
      kuanflag: false,
      shoukuanBox: {},
      shoukuanDetail: {},
      remarkVisible: false,
      remarkText: null,
    };
  },
  methods: {
    open(row) {
      this.render(row)
    },
    modifyRemark(){
      this.remarkVisible = true
      this.remarkText = this.shoukuanBox.remark
    },
    cancelEditRemark() {
      this.remarkVisible = false
    },
    confirmEditRemark() {
      const param = {
        id: this.shoukuanBox.id,
        remark: this.remarkText
      }
      modifyRemark(param).then(res=>{
        if(res.code===200){
          this.shoukuanBox.remark = this.remarkText
          this.remarkVisible = false
        } else {
          this.$message.error(res.message)
        }
      })
    },
    makeIt() {
      const that = this
      that.loading = true
      storage(that.shoukuanBox).then(res => {
        that.loading = false
        if(res.code === 200) {
          that.render(res.data)          
          that.$message.success(res.message)
        } else {
          that.$message.error(res.message)
        }
      }).catch(err => {
        that.loading = false
      })
    },
    render(row) {
      this.kuanflag = true;
      this.shoukuanBox = row;
      this.shoukuanDetail = row.payOrderSnapshot.detail;
      
      this.title = "结算单明细" + (row.isPreview ? '【预览】' : '已生成')
    },
    layerClose() {
      this.kuanflag = false;
    },
  },
  created() {
  },
};
</script>
<style scoped>
.kuanleft-box {
  float: left;
  width: 70%;
}
.kuanCode {
  float: right;
  width: 28%;
  display: block;
}
.kuanCode p {
  text-align: center;
  font-size: 12px;
  color: #07c160;
  font-weight: bold;
  margin-top: 5px;
}
.kuanRight {
  float: right;
  width: 15%;
  display: block;
}
.kuanTT {
  clear: both;
  display: block;
  overflow: hidden;
  padding: 5px 0;
}
.kuanTitle {
  display: block;
  font-size: 16px;
  color: #333;
  line-height: 40px;
  font-weight: bold;
}
.kuanBtn {
  width: 100%;
  padding: 62px 0;
  text-align: center;
  color: #fff;
  font-size: 16px;
  line-height: 42px;
  display: block;
  font-weight: bold;
  background-color: #07c160;
  cursor: pointer;
  border-radius: 10px;
  border: none;
}
.codesize {
  width: 200px;
  height: 200px;
  display: block;
  border: 5px solid #eee;
  margin: 0 auto;
  border-radius: 10px;
}
.kuantiao {
  display: block;
  overflow: hidden;
}
.kuantiao ol {
  float: left;
  width: 25%;
  font-size: 16px;
  color: #333;
  line-height: 40px;
  font-weight: bold;
}
.red {
  color: rgb(236, 78, 15);
}
.pay-desc {
  margin: 10px 0px 10px 0px;
  font-size: 13px;
  color: rgb(236, 78, 15);
}
</style>

