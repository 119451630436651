import request from '@/api/request.js'


export function edit (data) {
    return request({
      url: '/api/retailer/edit',
      method: 'POST',
      data
    })
  }

export function listRetailer (data) {
  return request({
    url: '/api/retailer/list',
    method: 'POST',
    data
  })
}  