import request from '@/api/request.js'

export function search (data) {
    return request({
      url: '/api/payOrder/search',
      method: 'POST',
      data
    })
}


export function getAccountBalance (data) {
  return request({
    url: '/api/balanceAccount/detail',
    method: 'POST',
    data
  })
}

export function getBalanceHistory (data) {
  return request({
    url: '/api/balanceAccount/history',
    method: 'POST',
    data
  })
}

export function applyWithdraw (data) {
  return request({
    url: '/api/balanceAccount/withdraw',
    method: 'POST',
    data
  })
}

export function queryBankList (data) {
  return request({
    url: '/api/balanceAccount/queryBankList',
    method: 'POST',
    data
  })
}

export function getInformation (data) {
  return request({
    url: '/api/balanceAccount/getInformation',
    method: 'POST',
    data
  })
}


export function submitInformation (data) {
  return request({
    url: '/api/balanceAccount/submit',
    method: 'POST',
    data
  })
}

// 上传身份证正面
export function uploadIdCardCopy (data) {
  return request({
    url: '/api/balanceAccount/uploadIdCardCopy',
    method: 'POST',
    data
  })
}

// 上传反面
export function uploadIdCardNational (data) {
  return request({
    url: '/api/balanceAccount/uploadIdCardNational',
    method: 'POST',
    data
  })
}

// 上传营业执照
export function uploadBusinessPaper (data) {
  return request({
    url: '/api/balanceAccount/uploadBusinessPaper',
    method: 'POST',
    data
  })
}

// 保存进件资料
export function saveInformation (data) {
  return request({
    url: '/api/balanceAccount/save',
    method: 'POST',
    data
  })
}

// 重复发短信邮件提醒
export function repeatNotify (data) {
  return request({
    url: '/api/balanceAccount/repeatNotify',
    method: 'POST',
    data
  })
}

// 查询易宝进件进度
export function progress (data) {
  return request({
    url: '/api/balanceAccount/progressYeePay',
    method: 'POST',
    data
  })
}

// 查询微信实名认证进度
export function progressWechat (data) {
  return request({
    url: '/api/balanceAccount/progressWechat',
    method: 'POST',
    data
  })
}

// 查询提现银行卡
queryWithdrawBank
export function queryWithdrawBank (data) {
  return request({
    url: '/api/balanceAccount/queryWithdrawBank',
    method: 'POST',
    data
  })
}

// 最近一次提现记录
export function getWithdrawal (data) {
  return request({
    url: '/api/balanceAccount/getWithdrawal',
    method: 'POST',
    data
  })
}

// 充值
export function recharge (data) {
  return request({
    url: '/api/balanceAccount/recharge',
    method: 'POST',
    data
  })
}

// 汇款
export function remit (data) {
  return request({
    url: '/api/payOrder/remit',
    method: 'POST',
    data
  })
}

// 作废账单
export function invalidatePayOrder (data) {
  return request({
    url: '/api/payOrder/invalidate',
    method: 'POST',
    data
  })
}


// 确认账单
export function confirmPayOrder (data) {
  return request({
    url: '/api/payOrder/confirm',
    method: 'POST',
    data
  })
}

// 线下结清账单
export function settledPayOrder (data) {
  return request({
    url: '/api/payOrder/settled',
    method: 'POST',
    data
  })
}

// 催收账
export function remindPayOrder (data) {
  return request({
    url: '/api/payOrder/remind',
    method: 'POST',
    data
  })
}

// 调整账单
export function adjustPayOrder (data) {
  return request({
    url: '/api/payOrder/adjust',
    method: 'POST',
    data
  })
}

// 查看账单详情
export function detail (data) {
  return request({
    url: '/api/payOrder/detail',
    method: 'POST',
    data
  })
}

// 下载账单excel
export function download (data) {
  return request({
    url: '/api/payOrder/download',
    method: 'POST',
    data
  })
}

// 模拟账单日
export function mockBill (data) {
  return request({
    url: '/api/payOrder/mock',
    method: 'POST',
    data
  })
}

// 自定义账单
export function makeCustomBill (data) {
  return request({
    url: '/api/payOrder/makeCustom',
    method: 'POST',
    data
  })
}

// 确认付款
export function payIt (data) {
  return request({
    url: '/api/payOrder/payIt',
    method: 'POST',
    data
  })
}

// 挂起账单
export function suspendIt (data) {
  return request({
    url: '/api/payOrder/suspend',
    method: 'POST',
    data
  })
}

// 预览后生成账单
export function storage(data) {
  return request({
    url: '/api/payOrder/storage',
    method: 'POST',
    data
  })
}

// 合并账单
export function mergePayOrder(data) {
  return request({
    url: '/api/payOrder/merge',
    method: 'POST',
    data
  })
}

// 修改账单备注
export function modifyRemark(data) {
  return request({
    url: '/api/payOrder/modifyRemark',
    method: 'POST',
    data
  })
}